import React, { useEffect, useRef, useState } from 'react';
import IconClose from '../IconClose/IconClose';
import VoiceSearch from './VoiceSearch/VoiceSearch';
import css from './AlgoliaKeywordSearch.module.css';

export default function KeywordInput(props) {
  const {
    onSearchChange,
    algoliaQueryInProgress,
    setIsSearchFocused,
    isSearchFocused,
    clearKeywordsClass,
    micClass,
    keyword,
    setKeyword,
    searchBoxRef,
    searchInputText,
    onFocus,
    isDesktopLayout,
    autoFocus,
    blockAutoScroll,
    showMap
  } = props;
  const [isInitial, setIsInitial] = useState(true);
  const inputRef = useRef(null);
  const isScrollingRef = useRef(false);
  const isUserInteracting = useRef(false);  // Flag to track user interaction with input

  // Debouncing the search input changes to prevent excessive re-renders
  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed
    if (autoFocus && !isMobile) {
      inputRef.current.focus();
    }
  }, [autoFocus]);
  
  // Mobile keyboard close during scroll
  useEffect(() => {
    let scrollTimeout;

    const handleScroll = () => {
      if (typeof document !== 'undefined' && inputRef.current === document.activeElement && !isUserInteracting.current) {
        inputRef.current.blur(); // Explicitly blur to close the keyboard if the user isn't interacting
      }
      isScrollingRef.current = true;
      
      // Set the interaction flag to false when scrolling
      isUserInteracting.current = false;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        isScrollingRef.current = false;
      }, 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(scrollTimeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Debounced search logic
  useEffect(() => {
    if (algoliaQueryInProgress) return;
  
    const timer = setTimeout(() => {
      if (keyword !== undefined && keyword !== null) {
        onSearchChange(keyword.trim());
      }
  
      if (typeof window !== 'undefined' && window.scrollTo && !isDesktopLayout && !blockAutoScroll && showMap) {
        window.scrollTo({
          top: 250,
          behavior: 'smooth',
        });
      }
  
      if (searchBoxRef?.current) {
        searchBoxRef.current.scrollTo(0, 0);
      }
    }, 500);
  
    return () => clearTimeout(timer);
  }, [keyword]);

  const handleChange = text => {
    if (!isSearchFocused) {
      setIsSearchFocused(true);
    }
    setKeyword(text);
  };

  const handleFocus = () => {
    // Prevent refocusing during scrolling if the user hasn't interacted
    if (isScrollingRef.current) {
      return;
    }

    // Reset initial state if focus is triggered after the first time
    if (isInitial) {
      setIsInitial(false);
    } else {
      setIsSearchFocused(true);
    }

    // Mark that the user is interacting
    isUserInteracting.current = true;

    // Trigger any provided `onFocus` callback
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const handleBlur = () => {
    // Reset the interaction flag when input loses focus
    isUserInteracting.current = false;
  };

  return (
    <div>
      <div className={css.keywordSearchWrapper}>
        <input
          className={css.keywordSearch}
          ref={inputRef}
          value={keyword}
          onChange={e => handleChange(e.target.value)}
          type="text"
          placeholder={searchInputText || 'Search by keyword'}
          onFocus={handleFocus}
          onBlur={handleBlur}  // Track when the input loses focus
        />
        {!!keyword ? (
          <button
            className={clearKeywordsClass}
            type="button"
            onClick={() => {
              setKeyword('');
              // onSearchChange('');
              if (searchBoxRef && searchBoxRef.current) {
                searchBoxRef.current.scrollTo(0, 0);
              }
            }}
          >
            <IconClose size="small" />
          </button>
        ) : null}
        <VoiceSearch className={micClass} onVoiceSearch={transcript => handleChange(transcript)} />
      </div>
    </div>
  );
}
