import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';
import moment from 'moment';

import { monthIdString } from '../../util/dates';
import { generateMonths } from '../../util/generators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { subUnitDivisors } from '../../config/settingsCurrency';
import { useConfiguration } from '../../context/configurationContext';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';
import { convertLineItems, convertPrice, formatMoney, unitDivisor } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { getRentalDateFromLocalStorage, sortPackages, userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { ModalInMobile, PrimaryButton, AvatarSmall, H1, H2, AvatarLarge, Ratings, AvatarMedium, NamedLink } from '../../components';
import PricePackages from './PricePackages/PricePackages';

import MobileBottomNavbar from '../MobileBottomNavbar/MobileBottomNavbar';
import css from './OrderPanel.module.css';
import { PICKUP_AT_OWNER_LOCATION, REQUIRED_UPFRONT_OPTIONAL, USAGE_MULTIPLE } from '../../util/constants';
import IconCard from '../SavedCardDetails/IconCard/IconCard';
import IconCollection from '../IconCollection/IconCollection';

const { Money } = sdkTypes;
// import { staticExchangeRates } from '../../config/configStripe';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

export const PER_DAY = 'perDayPrice';
export const PER_MINUTE = 'perMinutePrice';
export const PER_HOUR = 'perHourPrice';
export const TWO_DAYS = 'twoDaysPrice';
export const WEEKLY_PRICE = 'oneWeekPrice';
export const OVERNIGHT_PRICE = 'overnightPrice';
export const TWENTY_EIGHT_DAYS = 'twentyEightDaysPrice';
const DAY_PACKAGES = [PER_DAY, TWO_DAYS, WEEKLY_PRICE, OVERNIGHT_PRICE, TWENTY_EIGHT_DAYS];


const isWindowDefined = typeof window !== 'undefined';
const isDesktopLayout = isWindowDefined && window.innerWidth > MODAL_BREAKPOINT;

/**
 * Get average daily price for a booking period
 * @param {*} lineItems 
 * @param {*} bookingDates 
 * @returns avgPrice
 */
const getLineItemsDayAvgPrice = (lineItems, bookingDates) => {
  const { bookingStart, bookingEnd } = bookingDates;

  // Calculate number of days
  const units = moment(bookingEnd).diff(moment(bookingStart), 'days');
  const itemLineItem = lineItems.find(i =>
    Array.isArray(i.includeFor) && i.includeFor.includes('customer') && i.includeFor.includes('provider'));

  const { lineTotal } = itemLineItem;
  const { amount, currency } = lineTotal;

  const avgAmount = amount / units;
  const avgPrice = new Money(avgAmount, currency);
  return avgPrice;

};

/**
 * Get average daily price for a package
 * @param {*} price 
 * @param {*} package 
 */
const getPackageAvgPrice = (price, selectedPackage) => {
  if (price && price.amount) {
    switch (selectedPackage) {
      case PER_DAY:
        return price;
      case TWO_DAYS:
        return new Money((price.amount / 2), price.currency);
      case WEEKLY_PRICE:
        return new Money((price.amount / 7), price.currency);
      case TWENTY_EIGHT_DAYS:
        return new Money((price.amount / 28), price.currency);
      default:
        return price;
    }
  }
};

const getUnitsPrice = (price, units) =>
  new Money(price.amount * units, price.currency)

const getPerUnitPrice = (price, units) =>
  new Money(price.amount / units, price.currency)

/**
 * Convert the price in current user's currency
 * @param {*} param
 * @returns converted price.
 */
const getConvertedPrice = ({ lineItemsAvgPrice, packageAvgPrice, exchangeRatesReady, exchangeRates, currentUserCurrency }) => {
  const convertedPrice = !!lineItemsAvgPrice && !!exchangeRatesReady
    ? convertPrice(exchangeRates, lineItemsAvgPrice.amount, lineItemsAvgPrice.currency, currentUserCurrency)
    : !!packageAvgPrice && !!exchangeRatesReady
      ? convertPrice(exchangeRates, packageAvgPrice.amount, packageAvgPrice.currency, currentUserCurrency)
      : null;
  return convertedPrice;
};

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location, selectedPackage) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true, package: selectedPackage })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location,
  selectedPackage
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location, selectedPackage);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
    currentUserCurrency,
    selectedPackage
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);

  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, currentUserCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <H2 className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        {/* <FormattedMessage id="OrderPanel.perUnit" values={{ selectedPackage }} /> */}
        {DAY_PACKAGES.includes(selectedPackage)
          ? <FormattedMessage id="OrderPanel.perDayAvgPrice" />
          : <FormattedMessage id="OrderPanel.perUnit" values={{ selectedPackage }} />}
      </div>
    </H2>
  );
};


const PromotionalTextMaybe = (props) => {
  const { packages, currentUserCurrency, bookingDates, selectedPackage, intl } = props;

  const { bookingStart, bookingEnd } = bookingDates;
  const currentDaysCount = moment(bookingEnd).diff(moment(bookingStart), 'days');

  const getFormattedSavingsText = (savedAmount, additioanlDaysRequired) => {

    if (savedAmount && savedAmount > 0) {
      const divisor = unitDivisor(currentUserCurrency);
      const formattedAmount = intl.formatNumber((savedAmount / divisor), { style: 'currency', currency: currentUserCurrency });
      const text = `Book for ${additioanlDaysRequired} more ${additioanlDaysRequired > 1 ? 'days' : 'day'} and save ${formattedAmount} per day.`;
      return <p className={css.promotionalText}>{text}</p>;
    }
    return null;
  };

  const perDayPrice = packages[PER_DAY];
  const weeklyPrice = packages[WEEKLY_PRICE];
  const twentyEightDaysPrice = packages[TWENTY_EIGHT_DAYS];
  const twoDaysPerDayAvgPrice = packages[TWO_DAYS] ? getPackageAvgPrice(packages[TWO_DAYS], TWO_DAYS) : null;
  const weekPerDayAveragePrice = packages[WEEKLY_PRICE] ? getPackageAvgPrice(packages[WEEKLY_PRICE], WEEKLY_PRICE) : null;
  const twentyEightDaysPerDayAvgPrice = packages[TWENTY_EIGHT_DAYS] ? getPackageAvgPrice(packages[TWENTY_EIGHT_DAYS], TWENTY_EIGHT_DAYS) : null;


  if (selectedPackage === PER_DAY && currentDaysCount === 1) {
    const unitsPrice = getUnitsPrice(perDayPrice, currentDaysCount);
    // Discount is expected from the backend line items calculation
    // logic.
    if (unitsPrice.amount > weeklyPrice?.amount && currentDaysCount < 7) {
      const perUnitPrice = getPerUnitPrice(weeklyPrice, currentDaysCount);
      const formattedAmount = formatMoney(intl, perUnitPrice);
      return (<p className={css.promotionalText}>
        <FormattedMessage id='ListingPage.perDayPromotionalText' values={{ formattedAmount }} />
      </p>)
    } else if (unitsPrice.amount > twentyEightDaysPrice?.amount && currentDaysCount < 28) {
      const perUnitPrice = getPerUnitPrice(twentyEightDaysPrice, currentDaysCount);
      const formattedAmount = formatMoney(intl, perUnitPrice);
      return (<p className={css.promotionalText}>
        <FormattedMessage id='ListingPage.perDayPromotionalText' values={{ formattedAmount }} />
      </p>)
    }
  };

  if (currentDaysCount === 1 && packages[PER_DAY]) {
    return (
      getFormattedSavingsText(perDayPrice.amount - twoDaysPerDayAvgPrice?.amount, 1) ||
      getFormattedSavingsText(perDayPrice.amount - weekPerDayAveragePrice?.amount, 6) ||
      getFormattedSavingsText(perDayPrice.amount - twentyEightDaysPerDayAvgPrice?.amount, 27)
    );
  } else if (currentDaysCount >= 2 && currentDaysCount < 7) {
    const averagePrice = twoDaysPerDayAvgPrice ? twoDaysPerDayAvgPrice : packages[selectedPackage];
    return (
      getFormattedSavingsText(averagePrice.amount - weekPerDayAveragePrice?.amount, 7 - currentDaysCount) ||
      getFormattedSavingsText(averagePrice.amount - twentyEightDaysPerDayAvgPrice?.amount, 28 - currentDaysCount)
    );
  } else if (currentDaysCount >= 7 && currentDaysCount < 28) {
    const averagePrice = weekPerDayAveragePrice
      ? weekPerDayAveragePrice
      : selectedPackage === TWO_DAYS
        ? twoDaysPerDayAvgPrice
        : selectedPackage === PER_DAY
          ? perDayPrice
          : null;
    if (averagePrice) {
      return (
        getFormattedSavingsText(averagePrice.amount - twentyEightDaysPerDayAvgPrice?.amount, 28 - currentDaysCount)
      )
    }
  };
  return null;
};



const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    showCartButton,
    currentUser,
    isCurrentUserListing,
    onAddToCart,
    addToCartInProgress,
    addToCartError,
    cartNotification,
    routeConfiguration,
    onlyPricingSection,
    isVerified,
    reviews,
    showAuthorDetails,
    totalListings
  } = props;

  const config = useConfiguration();
  const [bookingDates, setBookingDates] = useState(null);

  const priceKeyFromSearch = parse(location.search).package;
  const isValidSearchPriceKey = [
    PER_DAY,
    PER_HOUR,
    WEEKLY_PRICE,
    PER_MINUTE,
    TWENTY_EIGHT_DAYS,
    TWO_DAYS,
    OVERNIGHT_PRICE
  ].includes(priceKeyFromSearch);
  const publicData = listing?.attributes?.publicData || {};
  const maxAvailableSeats = publicData?.quantity;
  const access_to_rentals = publicData?.access_to_rentals;
  const isPickupAtOwnerLocation = access_to_rentals?.includes(PICKUP_AT_OWNER_LOCATION);
  
  const defaultPriceKey = isValidSearchPriceKey
    ? priceKeyFromSearch
    : publicData?.defaultPriceKey
      ? publicData.defaultPriceKey
      : null;

  if (!defaultPriceKey) {
    return <div className={css.error}>Default price key is not not set</div>
  };

  const { currentUserCurrency } = useSelector(state => state.user);
  const { exchangeRates, fetRatesError, fetchRatesInProgress } = useSelector(state => state.exchangeRate);

  // Keeping per day package as default one.
  const [selectedPackage, setSelectedPackage] = useState(defaultPriceKey);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    const threshold = 20; // Adjust this value to control when the navbar reappears during upward scroll
    const topThreshold = 20; // Adjust this value to define "near the top" area
    const isScrollingDown = window.scrollY > lastScrollY;
    const isScrollingUp = window.scrollY < lastScrollY - threshold;
    const isNearTop = window.scrollY < topThreshold;

    if (isNearTop) {
      // Always show the navbar when near the top of the page
      setIsVisible(true);
    } else if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
      // Hide the navbar when at the bottom of the page
      setIsVisible(false);
    } else if (isScrollingDown) {
      // Hide the navbar during downward scrolls
      setIsVisible(false);
    } else if (isScrollingUp) {
      // Show the navbar during significant upward scrolls
      setIsVisible(true);
    }

    setLastScrollY(window.scrollY);
  };


  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  useEffect(() => {
    setSelectedPackage(defaultPriceKey);
  }, [defaultPriceKey]);

  const basePrice = listing.attributes.price;
  const prices = listing.attributes.publicData?.prices || { [defaultPriceKey]: { amount: basePrice.amount, currency: basePrice.currency } };
  const pricePackages = exchangeRates.length ? sortPackages(Object.keys(prices).reduce((packages, key) => {
    const pack = prices[key];
    const convertedPack = convertPrice(exchangeRates, pack.amount, pack.currency, currentUserCurrency)
    packages[key] = convertedPack;
    return packages;
  }, {}), publicData?.defaultPriceKey) : null;

  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  // const price = listing?.attributes?.price;
  const currentPrice = pricePackages ? pricePackages[selectedPackage] : null;
  const price = !!currentPrice ? new Money(currentPrice.amount, currentPrice.currency) : null;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const exchangeRatesReady = !fetchRatesInProgress && exchangeRates.length;

  const lineItemsAvgPrice = lineItems && lineItems.length && bookingDates && Object.keys(bookingDates) && getLineItemsDayAvgPrice(lineItems, bookingDates);
  const packageAvgPrice = getPackageAvgPrice(price, selectedPackage);

  // Replace the static exchange rates with the real exchange rates.
  const convertedPrice = getConvertedPrice({ lineItemsAvgPrice, packageAvgPrice, exchangeRatesReady, exchangeRates, currentUserCurrency });

  const showPriceMissing = isPaymentProcess && !price && exchangeRates.length;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  // const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const showInvalidCurrency = isPaymentProcess && convertedPrice?.currency !== currentUserCurrency;

  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const showFetchRatesError = fetRatesError && !exchangeRates?.length && !fetchRatesInProgress;

  const FetchExchangeRatesError = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.fetchExchangeRatesError" />
      </p>
    );
  };

  // We need to add dynamic dynamic fields to form for "Usage multiple calc type charges"
  const { additionalFees = [] } = publicData || {};

  // Convert the additionalFees rate to the current currency's equivalent rate
  const convertedAdditionalFees = additionalFees.map(fee => {
    const { rate } = fee;

    // Since rate is in big unit, but the money object expect in small
    // we need to convert it into small units.
    const divisor = unitDivisor(basePrice.currency);
    const updatedRate = rate * divisor;
    if (rate && exchangeRates?.length) {
      return {
        ...fee,
        rate: convertPrice(exchangeRates, updatedRate, basePrice.currency, currentUserCurrency)
      }
    }
    return fee;
  });
  
  // Usage multiple charges require user's input regarding number of units.
  // Optional charges require user's consent to apply.
  const usageMultipleCharges = convertedAdditionalFees?.filter(fee => fee.feeCalculationType === USAGE_MULTIPLE) || [];
  const otherOptionalCharges = convertedAdditionalFees?.filter(fee => (fee.feeCalculationType !== USAGE_MULTIPLE) && (fee.whenToApply === REQUIRED_UPFRONT_OPTIONAL));


  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  // const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const shouldHaveBookingTime = isBooking && [PER_HOUR, PER_MINUTE].includes(selectedPackage);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone && exchangeRatesReady;

  // const shouldHaveBookingDates =
  //   isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const shouldHaveBookingDates = isBooking && [PER_DAY, OVERNIGHT_PRICE, TWO_DAYS, WEEKLY_PRICE, TWENTY_EIGHT_DAYS].includes(selectedPackage);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone && exchangeRatesReady;


  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number' && exchangeRatesReady;

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const [bookingDateFormInitial, setBookingDateFormInitial] = useState({});
  const [bookingTimeFormInitial, setBookingTimeFormInitial] = useState({})
  let fetchTimeSlotInProgress = true;

  useEffect(() => {
    const { dates, selectedPackage: selectedStoredPackage } = getRentalDateFromLocalStorage(selectedPackage) || {};
    const { bookingStartDate, bookingEndDate, bookingStartTime, bookingEndTime } = dates || {};

    const monthlyTimeSlotsKeys = monthlyTimeSlots ? Object.keys(monthlyTimeSlots) : [];

    if (monthlyTimeSlotsKeys && monthlyTimeSlotsKeys.length) {
      const { timeSlots, fetchTimeSlotsInProgress } = monthlyTimeSlots[monthlyTimeSlotsKeys[0]];
      fetchTimeSlotInProgress = fetchTimeSlotsInProgress;

      if (bookingStartDate && bookingEndDate && (selectedStoredPackage === selectedPackage) && timeSlots && timeSlots.length && !fetchTimeSlotsInProgress) {
        const pickMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
          const monthId = monthIdString(date, timeZone);

          return monthlyTimeSlots?.[monthId]?.timeSlots || [];
        };

        const pickBookingMonthTimeSlots = (
          monthlyTimeSlots,
          startDate,
          endDate,
          timeZone
        ) => {
          // The generateMonths generator returns the first day of each month that is spanned
          // by the time range between start date and end date.
          const monthsInRange = generateMonths(startDate, endDate, timeZone);

          return monthsInRange.reduce((timeSlots, firstOfMonth) => {
            return [
              ...timeSlots,
              ...pickMonthlyTimeSlots(monthlyTimeSlots, firstOfMonth, timeZone),
            ];
          }, []);
        };
        const timeSlotsOnSelectedRange = pickBookingMonthTimeSlots(monthlyTimeSlots, bookingStartDate, bookingEndDate, timeZone);

        if (timeSlotsOnSelectedRange && timeSlotsOnSelectedRange.length) {
          const filteredTimeSlots = timeSlotsOnSelectedRange.filter(t => {
            const { start, end } = t.attributes || {};

            return (
              ((moment(start).tz(timeZone).unix() <= moment(bookingStartDate).tz(timeZone).unix())
                && (moment(end).tz(timeZone).unix() >= moment(bookingEndDate).tz(timeZone).unix())))
              || (moment(end).tz(timeZone).unix() > moment(bookingStartDate).tz(timeZone).unix())
              && (moment(start).tz(timeZone).unix() <= moment(bookingStartDate).tz(timeZone).unix())
          });


          if (filteredTimeSlots && filteredTimeSlots.length) {
            if (DAY_PACKAGES.includes(selectedPackage)) {
              setBookingDateFormInitial({ bookingDates: { startDate: new Date(bookingStartDate), endDate: new Date(bookingEndDate) } });
            } else {
              setBookingTimeFormInitial({
                bookingStartDate: { date: new Date(bookingStartDate) },
                bookingEndDate: { date: new Date(bookingEndDate) },
                bookingStartTime,
                bookingEndTime
              });
            }
          }
        }
      } else {
        setBookingTimeFormInitial({})
        setBookingDateFormInitial({})
      }
    }
  }, [monthlyTimeSlots, selectedPackage]);

  // console.log(bookingDateFormInitial, '&&& &&& => initialValues');

  const handlePackageSelect = (selectedPackage) => {
    if (!fetchLineItemsInProgress) {
      const { pathname, search, state } = location;
      const searchString = `?${stringify({ ...parse(search), package: selectedPackage })}`;
      history.push(`${pathname}${searchString}`, state);
      setBookingDates(null);
    }
  };

  const { profile } = author?.attributes || {};
  const  { publicData: uPublicData, displayName } = profile || {};
  const { city, rating } = uPublicData || {};
  const searchString = !!city && !!displayName
  ? `?name=${displayName}&city=${city}`
  : !!displayName
    ? `?name=${displayName}`
    : !!city
      ? `?city=${city}`
      : ''

const linkProps = author.id
  ? { name: 'ProfilePage', params: { id: author.id.uuid }, to: { search: searchString } }
  : { name: 'ProfileBasePage' };


  return (
    <>{onlyPricingSection ?
      <div className={classes}>
        <PriceMaybe
          price={convertedPrice}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          selectedCurrency={currentUserCurrency}
          selectedPackage={selectedPackage}
        />
        {!!pricePackages
          ? <PricePackages
            packages={pricePackages}
            intl={intl}
            setSelectedPackage={handlePackageSelect}
            selectedPackage={selectedPackage}
            currentUserCurrency={currentUserCurrency}
          />
          : null}
        {bookingDates
          ? <PromotionalTextMaybe
            bookingDates={bookingDates}
            selectedPackage={selectedPackage}
            packages={pricePackages}
            currentUserCurrency={currentUserCurrency}
            intl={intl}
          />
          : null}

        {showAuthorDetails ? <div className={css.authorMob}>
          <AvatarMedium user={author} className={css.avatarMediumMob} />
          <div className={css.providerNameLinkedMob}>
            <span>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
              {isVerified ? <IconCard className={css.verifyShield} brand="verifiedShield" /> : null}
            </span>
            <Ratings className={css.ratingsMob} rating={rating} />

            <div>
              <a className={css.reviewsMob} href='#reviews'><FormattedMessage id='OrderPanel.reviews' values={{ count: reviews?.length }} /></a>
              <span> | </span>
              <NamedLink className={css.totalListings} {...linkProps}>{totalListings} {totalListings > 1 ? 'Listings' : 'Listing'}</NamedLink>
            </div>
          </div>
          {!isCurrentUserListing ? <button className={css.contactButton} type='button' onClick={() => onContactUser(author)}><IconCollection name="messageIcon"/></button>:null}
        </div> : null}
      </div>
      :
      <div className={classes}>
        <ModalInMobile
          containerClassName={css.modalContainer}
          id="OrderFormInModal"
          isModalOpenOnMobile={isOrderOpen}
          onClose={() => closeOrderModal(history, location)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className={css.modalHeading}>
            <H1 className={css.heading}>{title}</H1>
          </div>
          <div className={css.orderHeading}>
            {titleDesktop ? titleDesktop : <H1 className={titleClasses}>{title}</H1>}
            {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
          </div>

          <div className={css.priceAndPackages}>
            <PriceMaybe
              price={convertedPrice}
              publicData={publicData}
              validListingTypes={validListingTypes}
              intl={intl}
              marketplaceCurrency={marketplaceCurrency}
              selectedCurrency={currentUserCurrency}
              selectedPackage={selectedPackage}
            />

            {!!pricePackages ? <PricePackages packages={pricePackages} intl={intl} setSelectedPackage={handlePackageSelect} selectedPackage={selectedPackage} currentUserCurrency={currentUserCurrency} /> : null}
          </div>


          {bookingDates ? <PromotionalTextMaybe bookingDates={bookingDates} selectedPackage={selectedPackage} packages={pricePackages} currentUserCurrency={currentUserCurrency} intl={intl} /> : null}

          {showAuthorDetails ? <div className={css.author}>
            <AvatarMedium user={author} className={css.avatarMedium} />
            <div className={css.providerNameLinked}>
              <span>
                <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
                {isVerified ? <IconCard className={css.verifyShield} brand="verifiedShield" /> : null}
              </span>
              <Ratings className={css.ratings} rating={rating} />
              <div>
                <a className={css.reviews} href='#reviews'><FormattedMessage id='OrderPanel.reviews' values={{ count: reviews?.length }} /></a>
                <span> | </span>
                <NamedLink className={css.totalListings} {...linkProps}>{totalListings} {totalListings > 1 ? 'Listings' : 'Listing'}</NamedLink>
              </div>
            </div>
            {!isCurrentUserListing ? <button className={css.contactButton} type='button' onClick={() => onContactUser(author)}><IconCollection name="messageIcon"/></button>:null}

            <span className={css.providerNamePlain}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
              {isVerified ? <IconCard className={css.verifyShield} brand="verifiedShield" /> : null}
              <Ratings className={css.ratings} rating={rating} />
            </span>
          </div> : null}

          {showPriceMissing ? (
            <PriceMissing />
          ) : showFetchRatesError ? (
            <FetchExchangeRatesError />
          ) : showBookingTimeForm ? (
            <BookingTimeForm
              className={css.bookingForm}
              formId="OrderPanelBookingTimeForm"
              initialValues={{}}
              lineItemUnitType={lineItemUnitType}
              onSubmit={(e) => onSubmit({ ...e, package: selectedPackage })}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
              listingId={listing.id}
              listing={listing}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
              selectedPackage={selectedPackage}
              showCartButton={showCartButton}
              currentUser={currentUser}
              isCurrentUserListing={isCurrentUserListing}
              onAddToCart={onAddToCart}
              addToCartInProgress={addToCartInProgress}
              addToCartError={addToCartError}
              cartNotification={cartNotification}
              authorId={author?.id?.uuid}
              config={config}
              history={history}
              routeConfiguration={routeConfiguration}
              autoFocused={!isDesktopLayout}
              usageMultipleCharges={usageMultipleCharges}
              currentUserCurrency={currentUserCurrency}
              otherOptionalCharges={otherOptionalCharges}
              initialScroll={!isDesktopLayout}
              maxAvailableSeats={maxAvailableSeats}
              isPickupAtOwnerLocation={isPickupAtOwnerLocation}
            />
          ) : showBookingDatesForm ? (
            <BookingDatesForm
              className={css.bookingForm}
              formId="OrderPanelBookingDatesForm"
              lineItemUnitType={lineItemUnitType}
              setBookingDates={setBookingDates}
              onSubmit={(e) => onSubmit({ ...e, package: selectedPackage })}
              price={price}
              initialValues={{}}
              marketplaceCurrency={marketplaceCurrency}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
              listingId={listing.id}
              listing={listing}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              timeZone={timeZone}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
              selectedPackage={selectedPackage}
              showCartButton={showCartButton}
              currentUser={currentUser}
              isCurrentUserListing={isCurrentUserListing}
              onAddToCart={onAddToCart}
              addToCartInProgress={addToCartInProgress}
              addToCartError={addToCartError}
              authorId={author?.id?.uuid}
              config={config}
              cartNotification={cartNotification}
              history={history}
              routeConfiguration={routeConfiguration}
              autoFocused={true}
              usageMultipleCharges={usageMultipleCharges}
              currentUserCurrency={currentUserCurrency}
              otherOptionalCharges={otherOptionalCharges}
              initialScroll={!isDesktopLayout}
              maxAvailableSeats={maxAvailableSeats}
              isPickupAtOwnerLocation={isPickupAtOwnerLocation}
            />
          ) : showProductOrderForm ? (
            <ProductOrderForm
              formId="OrderPanelProductOrderForm"
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              currentStock={currentStock}
              allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
              pickupEnabled={pickupEnabled && displayPickup}
              shippingEnabled={shippingEnabled && displayShipping}
              displayDeliveryMethod={displayPickup || displayShipping}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onContactUser={onContactUser}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
            />
          ) : showInquiryForm ? (
            <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
          ) : !isKnownProcess ? (
            <p className={css.errorSidebar}>
              <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
            </p>
          ) : null}

        </ModalInMobile>
        <div className={css.openOrderFormContainer}>
          <MobileBottomNavbar />
          {/* {!!pricePackages ? <PricePackages packages={pricePackages} intl={intl} setSelectedPackage={setSelectedPackage} selectedPackage={selectedPackage} currentUserCurrency={currentUserCurrency} /> : null}
        <PromotionalTextMaybe selectedPackage={selectedPackage} packages={pricePackages} intl={intl} /> */}
          <div className={classNames(css.openOrderForm, isVisible ? css.openOrderFormMargin : null)}>
            {/* <PriceMaybe
            price={convertedPrice}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
            marketplaceCurrency={marketplaceCurrency}
            selectedCurrency={currentUserCurrency}
            selectedPackage={selectedPackage}
          /> */}

            <PrimaryButton
              onClick={() => (typeof window !== 'undefined' ? window.location.href = `tel:${process.env.REACT_APP_TEAM_PHONE_NUMBER}` : null)}
              className={css.callButton}
            >
              <FormattedMessage id="OrderPanel.callButtonText" />
            </PrimaryButton>

            {isClosed ? (
              <div className={css.closedListingButton}>
                <FormattedMessage id="OrderPanel.closedListingButtonText" />
              </div>
            ) : (
              <PrimaryButton
                onClick={handleSubmit(
                  isOwnListing,
                  isClosed,
                  showInquiryForm,
                  onSubmit,
                  history,
                  location,
                  selectedPackage
                )}
                disabled={isOutOfStock}
              >
                {isBooking ? (
                  <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
                ) : isOutOfStock ? (
                  <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
                ) : isPurchase ? (
                  <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
                ) : (
                  <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
                )}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    }
    </>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);