/* Stripe related configuration.

NOTE: REACT_APP_STRIPE_PUBLISHABLE_KEY is mandatory environment variable.
This variable is set in a hidden file: .env
To make Stripe connection work, you also need to set Stripe's private key in the Sharetribe Console.
*/

export const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
export const dayCountAvailableForBooking = 365;

/**
 * Default merchant category code (MCC)
 * MCCs are used to classify businesses by the type of goods or services they provide.
 *
 * In this template, we use code 5734 Computer Software Stores as a default for all the connected accounts.
 *
 * See the whole list of MCC codes from https://stripe.com/docs/connect/setting-mcc#list
 */
export const defaultMCC = '5734';

/*
Stripe only supports payments in certain countries, see full list
at https://stripe.com/global

You can find the bank account formats from https://stripe.com/docs/connect/payouts-bank-accounts
*/

export const supportedCountries = [
  {
    //Australia
    country: 'Australia',
    code: 'AU',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    // Austria
    country: 'Austria',
    code: 'AT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Belgium
    country: 'Belgium',
    code: 'BE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Bulgraia
    country: 'Bulgraia',
    code: 'BG',
    currency: 'BGN',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Canada
    country: 'Canada',
    code: 'CA',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    //Cyprus
    country: 'Cyprus',
    code: 'CY',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //	Czech Republic
    country: 'Czech Republic',
    code: 'CZ',
    currency: 'CZK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Denmark
    country: 'Denmark',
    code: 'DK',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Estionia
    country: 'Estionia',
    code: 'EE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Finland
    country: 'Finland',
    code: 'FI',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // France
    country: 'France',
    code: 'FR',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Germany
    country: 'Germany',
    code: 'DE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Greece
    country: 'Greece',
    code: 'GR',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Hong Kong
    country: 'Hong Kong',
    code: 'HK',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Ireland
    country: 'Ireland',
    code: 'IE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Italy
    country: 'Italy',
    code: 'IT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Japan
    country: 'Japan',
    code: 'JP',
    currency: 'JPY',
    accountConfig: {
      bankName: true,
      branchName: true,
      bankCode: true,
      branchCode: true,
      accountNumber: true,
      accountOwnerName: true,
    },
  },
  {
    // Latvia
    country: 'Latvia',
    code: 'LV',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Lithuania
    country: 'Lithuania',
    code: 'LT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Luxembourg
    country: 'Luxembourg',
    code: 'LU',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Malta
    country: 'Malta',
    code: 'MT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Mexico
    country: 'Mexico',
    code: 'MX',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Netherlands
    country: 'Netherlands',
    code: 'NL',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // New Zealand
    country: 'New Zealand',
    code: 'NZ',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Norway
    country: 'Norway',
    code: 'NO',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Poland
    country: 'Poland',
    code: 'PL',
    currency: 'PLN',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Portugal
    country: 'Portugal',
    code: 'PT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Romania
    country: 'Romania',
    code: 'RO',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Singapore
    country: 'Singapore',
    code: 'SG',
    currency: 'SGD',
    accountConfig: {
      bankCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Slovakia
    country: 'Slovakia',
    code: 'SK',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Slovenia
    country: 'Slovenia',
    code: 'SI',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Spain
    country: 'Spain',
    code: 'ES',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Sweden
    country: 'Sweden',
    code: 'SE',
    currency: 'SEK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Switzerland
    country: 'Switzerland',
    code: 'CH',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // United Kingdom
    country: 'United Kingdom',
    code: 'GB',
    currency: 'GBP',
    accountConfig: {
      sortCode: true,
      accountNumber: true,
    },
  },
  {
    // United States
    country: 'United States',
    code: 'US',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
];

export const supportedCurrencies = ["AUD", "EUR", "BGN", "CAD", "CZK", "DKK", "HKD", "JPY", "MXN", "NZD", "NOK", "PLN", "RON", "SGD", "SEK", "CHF", "GBP", "USD"];
export const currencyData = [
  { code: "AUD", countryCode: "AU" },
  { code: "BGN", countryCode: "BG" },
  { code: "CAD", countryCode: "CA" },
  { code: "CZK", countryCode: "CZ" },
  { code: "CHF", countryCode: "CH" },
  { code: "CRC", countryCode: "CR" },
  { code: "DKK", countryCode: "DK" },
  { code: "EUR", countryCode: "EU" },
  { code: "GBP", countryCode: "GB" },
  { code: "HKD", countryCode: "HK" },
  { code: "JPY", countryCode: "JP" },
  { code: "MXN", countryCode: "MX" },
  { code: "NZD", countryCode: "NZ" },
  { code: "NOK", countryCode: "NO" },
  { code: "PLN", countryCode: "PL" },
  { code: "RON", countryCode: "RO" },
  { code: "SGD", countryCode: "SG" },
  { code: "SEK", countryCode: "SE" },
  { code: "USD", countryCode: "US" },
];





export const staticExchangeRates = [
  // # GBP
  { from_currency: "GBP", to_currency: "AUD", exchange_rate: 1.78 },
  { from_currency: "GBP", to_currency: "USD", exchange_rate: 1.25 },
  { from_currency: "GBP", to_currency: "EUR", exchange_rate: 1.15 },
  { from_currency: "GBP", to_currency: "BGN", exchange_rate: 2.24 },
  { from_currency: "GBP", to_currency: "CAD", exchange_rate: 1.60 },
  { from_currency: "GBP", to_currency: "CZK", exchange_rate: 26.20 },
  { from_currency: "GBP", to_currency: "DKK", exchange_rate: 8.49 },
  { from_currency: "GBP", to_currency: "HKD", exchange_rate: 9.29 },
  { from_currency: "GBP", to_currency: "JPY", exchange_rate: 126.50 },
  { from_currency: "GBP", to_currency: "MXN", exchange_rate: 23.10 },
  { from_currency: "GBP", to_currency: "NZD", exchange_rate: 1.73 },
  { from_currency: "GBP", to_currency: "NOK", exchange_rate: 10.55 },
  { from_currency: "GBP", to_currency: "PLN", exchange_rate: 5.02 },
  { from_currency: "GBP", to_currency: "RON", exchange_rate: 5.82 },
  { from_currency: "GBP", to_currency: "SGD", exchange_rate: 1.83 },
  { from_currency: "GBP", to_currency: "SEK", exchange_rate: 11.76 },
  { from_currency: "GBP", to_currency: "CHF", exchange_rate: 1.27 },
  { from_currency: "GBP", to_currency: "AUD", exchange_rate: 1.78 },

  // # DKK
  { from_currency: "DKK", to_currency: "AUD", exchange_rate: 0.23 },
  { from_currency: "DKK", to_currency: "USD", exchange_rate: 0.17 },
  { from_currency: "DKK", to_currency: "EUR", exchange_rate: 0.16 },
  { from_currency: "DKK", to_currency: "BGN", exchange_rate: 0.32 },
  { from_currency: "DKK", to_currency: "CAD", exchange_rate: 0.23 },
  { from_currency: "DKK", to_currency: "CZK", exchange_rate: 2.79 },
  { from_currency: "DKK", to_currency: "HKD", exchange_rate: 1.10 },
  { from_currency: "DKK", to_currency: "JPY", exchange_rate: 15.10 },
  { from_currency: "DKK", to_currency: "MXN", exchange_rate: 2.75 },
  { from_currency: "DKK", to_currency: "NZD", exchange_rate: 0.20 },
  { from_currency: "DKK", to_currency: "NOK", exchange_rate: 1.22 },
  { from_currency: "DKK", to_currency: "PLN", exchange_rate: 0.58 },
  { from_currency: "DKK", to_currency: "RON", exchange_rate: 0.67 },
  { from_currency: "DKK", to_currency: "SGD", exchange_rate: 0.21 },
  { from_currency: "DKK", to_currency: "SEK", exchange_rate: 1.36 },
  { from_currency: "DKK", to_currency: "CHF", exchange_rate: 0.15 },
  { from_currency: "DKK", to_currency: "GBP", exchange_rate: 0.12 },
  { from_currency: "DKK", to_currency: "AUD", exchange_rate: 0.23 },

  // # AUD
  { from_currency: "AUD", to_currency: "EUR", exchange_rate: 0.65 },
  { from_currency: "AUD", to_currency: "BGN", exchange_rate: 1.28 },
  { from_currency: "AUD", to_currency: "CAD", exchange_rate: 0.95 },
  { from_currency: "AUD", to_currency: "CZK", exchange_rate: 16.50 },
  { from_currency: "AUD", to_currency: "DKK", exchange_rate: 4.24 },
  { from_currency: "AUD", to_currency: "HKD", exchange_rate: 5.84 },
  { from_currency: "AUD", to_currency: "JPY", exchange_rate: 80.50 },
  { from_currency: "AUD", to_currency: "MXN", exchange_rate: 14.60 },
  { from_currency: "AUD", to_currency: "NZD", exchange_rate: 1.08 },
  { from_currency: "AUD", to_currency: "NOK", exchange_rate: 5.85 },
  { from_currency: "AUD", to_currency: "PLN", exchange_rate: 2.78 },
  { from_currency: "AUD", to_currency: "RON", exchange_rate: 3.23 },
  { from_currency: "AUD", to_currency: "SGD", exchange_rate: 1.02 },
  { from_currency: "AUD", to_currency: "SEK", exchange_rate: 6.54 },
  { from_currency: "AUD", to_currency: "CHF", exchange_rate: 0.70 },
  { from_currency: "AUD", to_currency: "GBP", exchange_rate: 0.56 },
  { from_currency: "AUD", to_currency: "USD", exchange_rate: 0.73 },
  { from_currency: "USD", to_currency: "EUR", exchange_rate: 0.85 },

  // # USD
  { from_currency: "USD", to_currency: "AUD", exchange_rate: 1.37 },
  { from_currency: "USD", to_currency: "EUR", exchange_rate: 0.93 },
  { from_currency: "USD", to_currency: "BGN", exchange_rate: 1.84 },
  { from_currency: "USD", to_currency: "CAD", exchange_rate: 1.25 },
  { from_currency: "USD", to_currency: "CZK", exchange_rate: 20.75 },
  { from_currency: "USD", to_currency: "DKK", exchange_rate: 6.71 },
  { from_currency: "USD", to_currency: "HKD", exchange_rate: 7.34 },
  { from_currency: "USD", to_currency: "JPY", exchange_rate: 100.50 },
  { from_currency: "USD", to_currency: "MXN", exchange_rate: 18.25 },
  { from_currency: "USD", to_currency: "NZD", exchange_rate: 1.37 },
  { from_currency: "USD", to_currency: "NOK", exchange_rate: 8.35 },
  { from_currency: "USD", to_currency: "PLN", exchange_rate: 3.96 },
  { from_currency: "USD", to_currency: "RON", exchange_rate: 4.60 },
  { from_currency: "USD", to_currency: "SGD", exchange_rate: 1.45 },
  { from_currency: "USD", to_currency: "SEK", exchange_rate: 9.29 },
  { from_currency: "USD", to_currency: "CHF", exchange_rate: 1.00 },
  { from_currency: "USD", to_currency: "GBP", exchange_rate: 0.80 },
  { from_currency: "USD", to_currency: "AUD", exchange_rate: 1.37 },

  // # EUR
  { from_currency: "EUR", to_currency: "AUD", exchange_rate: 1.54 },
  { from_currency: "EUR", to_currency: "USD", exchange_rate: 1.08 },
  { from_currency: "EUR", to_currency: "BGN", exchange_rate: 1.95 },
  { from_currency: "EUR", to_currency: "CAD", exchange_rate: 1.33 },
  { from_currency: "EUR", to_currency: "CZK", exchange_rate: 22.05 },
  { from_currency: "EUR", to_currency: "DKK", exchange_rate: 7.15 },
  { from_currency: "EUR", to_currency: "HKD", exchange_rate: 7.84 },
  { from_currency: "EUR", to_currency: "JPY", exchange_rate: 107.50 },
  { from_currency: "EUR", to_currency: "MXN", exchange_rate: 19.45 },
  { from_currency: "EUR", to_currency: "NZD", exchange_rate: 1.46 },
  { from_currency: "EUR", to_currency: "NOK", exchange_rate: 8.95 },
  { from_currency: "EUR", to_currency: "PLN", exchange_rate: 4.26 },
  { from_currency: "EUR", to_currency: "RON", exchange_rate: 4.94 },
  { from_currency: "EUR", to_currency: "SGD", exchange_rate: 1.55 },
  { from_currency: "EUR", to_currency: "SEK", exchange_rate: 9.96 },
  { from_currency: "EUR", to_currency: "CHF", exchange_rate: 1.07 },
  { from_currency: "EUR", to_currency: "GBP", exchange_rate: 0.87 },
  { from_currency: "EUR", to_currency: "AUD", exchange_rate: 1.54 },

  // # BGN
  { from_currency: "BGN", to_currency: "AUD", exchange_rate: 0.78 },
  { from_currency: "BGN", to_currency: "USD", exchange_rate: 0.54 },
  { from_currency: "BGN", to_currency: "EUR", exchange_rate: 0.51 },
  { from_currency: "BGN", to_currency: "CAD", exchange_rate: 0.68 },
  { from_currency: "BGN", to_currency: "CZK", exchange_rate: 11.28 },
  { from_currency: "BGN", to_currency: "DKK", exchange_rate: 3.65 },
  { from_currency: "BGN", to_currency: "HKD", exchange_rate: 4.00 },
  { from_currency: "BGN", to_currency: "JPY", exchange_rate: 54.50 },
  { from_currency: "BGN", to_currency: "MXN", exchange_rate: 9.90 },
  { from_currency: "BGN", to_currency: "NZD", exchange_rate: 0.74 },
  { from_currency: "BGN", to_currency: "NOK", exchange_rate: 4.52 },
  { from_currency: "BGN", to_currency: "PLN", exchange_rate: 2.15 },
  { from_currency: "BGN", to_currency: "RON", exchange_rate: 2.49 },
  { from_currency: "BGN", to_currency: "SGD", exchange_rate: 0.78 },
  { from_currency: "BGN", to_currency: "SEK", exchange_rate: 5.02 },
  { from_currency: "BGN", to_currency: "CHF", exchange_rate: 0.54 },
  { from_currency: "BGN", to_currency: "GBP", exchange_rate: 0.44 },
  { from_currency: "BGN", to_currency: "AUD", exchange_rate: 0.78 },

  // # CAD
  { from_currency: "CAD", to_currency: "AUD", exchange_rate: 1.05 },
  { from_currency: "CAD", to_currency: "USD", exchange_rate: 0.80 },
  { from_currency: "CAD", to_currency: "EUR", exchange_rate: 0.75 },
  { from_currency: "CAD", to_currency: "BGN", exchange_rate: 1.47 },
  { from_currency: "CAD", to_currency: "CZK", exchange_rate: 16.25 },
  { from_currency: "CAD", to_currency: "DKK", exchange_rate: 5.26 },
  { from_currency: "CAD", to_currency: "HKD", exchange_rate: 5.76 },
  { from_currency: "CAD", to_currency: "JPY", exchange_rate: 78.50 },
  { from_currency: "CAD", to_currency: "MXN", exchange_rate: 14.30 },
  { from_currency: "CAD", to_currency: "NZD", exchange_rate: 1.06 },
  { from_currency: "CAD", to_currency: "NOK", exchange_rate: 5.75 },
  { from_currency: "CAD", to_currency: "PLN", exchange_rate: 2.74 },
  { from_currency: "CAD", to_currency: "RON", exchange_rate: 3.19 },
  { from_currency: "CAD", to_currency: "SGD", exchange_rate: 1.01 },
  { from_currency: "CAD", to_currency: "SEK", exchange_rate: 6.48 },
  { from_currency: "CAD", to_currency: "CHF", exchange_rate: 0.70 },
  { from_currency: "CAD", to_currency: "GBP", exchange_rate: 0.56 },
  { from_currency: "CAD", to_currency: "AUD", exchange_rate: 1.05 },

  // # CZK
  { from_currency: "CZK", to_currency: "AUD", exchange_rate: 0.061 },
  { from_currency: "CZK", to_currency: "USD", exchange_rate: 0.047 },
  { from_currency: "CZK", to_currency: "EUR", exchange_rate: 0.043 },
  { from_currency: "CZK", to_currency: "BGN", exchange_rate: 0.088 },
  { from_currency: "CZK", to_currency: "CAD", exchange_rate: 0.062 },
  { from_currency: "CZK", to_currency: "DKK", exchange_rate: 0.32 },
  { from_currency: "CZK", to_currency: "HKD", exchange_rate: 0.35 },
  { from_currency: "CZK", to_currency: "JPY", exchange_rate: 4.76 },
  { from_currency: "CZK", to_currency: "MXN", exchange_rate: 0.87 },
  { from_currency: "CZK", to_currency: "NZD", exchange_rate: 0.065 },
  { from_currency: "CZK", to_currency: "NOK", exchange_rate: 0.40 },
  { from_currency: "CZK", to_currency: "PLN", exchange_rate: 0.19 },
  { from_currency: "CZK", to_currency: "RON", exchange_rate: 0.22 },
  { from_currency: "CZK", to_currency: "SGD", exchange_rate: 0.069 },
  { from_currency: "CZK", to_currency: "SEK", exchange_rate: 0.45 },
  { from_currency: "CZK", to_currency: "CHF", exchange_rate: 0.048 },
  { from_currency: "CZK", to_currency: "GBP", exchange_rate: 0.039 },
  { from_currency: "CZK", to_currency: "AUD", exchange_rate: 0.061 },

  // # DKK
  { from_currency: "DKK", to_currency: "AUD", exchange_rate: 0.23 },
  { from_currency: "DKK", to_currency: "USD", exchange_rate: 0.17 },
  { from_currency: "DKK", to_currency: "EUR", exchange_rate: 0.16 },
  { from_currency: "DKK", to_currency: "BGN", exchange_rate: 0.32 },
  { from_currency: "DKK", to_currency: "CAD", exchange_rate: 0.23 },
  { from_currency: "DKK", to_currency: "CZK", exchange_rate: 2.79 },
  { from_currency: "DKK", to_currency: "HKD", exchange_rate: 1.10 },
  { from_currency: "DKK", to_currency: "JPY", exchange_rate: 15.10 },
  { from_currency: "DKK", to_currency: "MXN", exchange_rate: 2.75 },
  { from_currency: "DKK", to_currency: "NZD", exchange_rate: 0.20 },
  { from_currency: "DKK", to_currency: "NOK", exchange_rate: 1.22 },
  { from_currency: "DKK", to_currency: "PLN", exchange_rate: 0.58 },
  { from_currency: "DKK", to_currency: "RON", exchange_rate: 0.67 },
  { from_currency: "DKK", to_currency: "SGD", exchange_rate: 0.21 },
  { from_currency: "DKK", to_currency: "SEK", exchange_rate: 1.36 },
  { from_currency: "DKK", to_currency: "CHF", exchange_rate: 0.15 },
  { from_currency: "DKK", to_currency: "GBP", exchange_rate: 0.12 },
  { from_currency: "DKK", to_currency: "AUD", exchange_rate: 0.23 },

  // # HKD
  { from_currency: "HKD", to_currency: "AUD", exchange_rate: 0.20 },
  { from_currency: "HKD", to_currency: "USD", exchange_rate: 0.13 },
  { from_currency: "HKD", to_currency: "EUR", exchange_rate: 0.12 },
  { from_currency: "HKD", to_currency: "BGN", exchange_rate: 0.25 },
  { from_currency: "HKD", to_currency: "CAD", exchange_rate: 0.17 },
  { from_currency: "HKD", to_currency: "CZK", exchange_rate: 2.07 },
  { from_currency: "HKD", to_currency: "DKK", exchange_rate: 7.92 },
  { from_currency: "HKD", to_currency: "JPY", exchange_rate: 13.70 },
  { from_currency: "HKD", to_currency: "MXN", exchange_rate: 2.49 },
  { from_currency: "HKD", to_currency: "NZD", exchange_rate: 0.19 },
  { from_currency: "HKD", to_currency: "NOK", exchange_rate: 1.14 },
  { from_currency: "HKD", to_currency: "PLN", exchange_rate: 0.54 },
  { from_currency: "HKD", to_currency: "RON", exchange_rate: 0.63 },
  { from_currency: "HKD", to_currency: "SGD", exchange_rate: 0.20 },
  { from_currency: "HKD", to_currency: "SEK", exchange_rate: 1.27 },
  { from_currency: "HKD", to_currency: "CHF", exchange_rate: 0.14 },
  { from_currency: "HKD", to_currency: "GBP", exchange_rate: 0.11 },
  { from_currency: "HKD", to_currency: "AUD", exchange_rate: 0.20 },

  // # JPY
  { from_currency: "JPY", to_currency: "AUD", exchange_rate: 0.016 },
  { from_currency: "JPY", to_currency: "USD", exchange_rate: 0.012 },
  { from_currency: "JPY", to_currency: "EUR", exchange_rate: 0.011 },
  { from_currency: "JPY", to_currency: "BGN", exchange_rate: 0.022 },
  { from_currency: "JPY", to_currency: "CAD", exchange_rate: 0.015 },
  { from_currency: "JPY", to_currency: "CZK", exchange_rate: 1.90 },
  { from_currency: "JPY", to_currency: "DKK", exchange_rate: 0.066 },
  { from_currency: "JPY", to_currency: "HKD", exchange_rate: 0.073 },
  { from_currency: "JPY", to_currency: "MXN", exchange_rate: 0.18 },
  { from_currency: "JPY", to_currency: "NZD", exchange_rate: 0.013 },
  { from_currency: "JPY", to_currency: "NOK", exchange_rate: 0.083 },
  { from_currency: "JPY", to_currency: "PLN", exchange_rate: 0.039 },
  { from_currency: "JPY", to_currency: "RON", exchange_rate: 0.045 },
  { from_currency: "JPY", to_currency: "SGD", exchange_rate: 0.014 },
  { from_currency: "JPY", to_currency: "SEK", exchange_rate: 0.092 },
  { from_currency: "JPY", to_currency: "CHF", exchange_rate: 0.01 },
  { from_currency: "JPY", to_currency: "GBP", exchange_rate: 0.008 },
  { from_currency: "JPY", to_currency: "AUD", exchange_rate: 0.016 },

  // # GBP
  { from_currency: "GBP", to_currency: "AUD", exchange_rate: 1.78 },
  { from_currency: "GBP", to_currency: "USD", exchange_rate: 1.25 },
  { from_currency: "GBP", to_currency: "EUR", exchange_rate: 1.15 },
  { from_currency: "GBP", to_currency: "BGN", exchange_rate: 2.24 },
  { from_currency: "GBP", to_currency: "CAD", exchange_rate: 1.60 },
  { from_currency: "GBP", to_currency: "CZK", exchange_rate: 26.20 },
  { from_currency: "GBP", to_currency: "DKK", exchange_rate: 8.49 },
  { from_currency: "GBP", to_currency: "HKD", exchange_rate: 9.29 },
  { from_currency: "GBP", to_currency: "JPY", exchange_rate: 126.50 },
  { from_currency: "GBP", to_currency: "MXN", exchange_rate: 23.10 },
  { from_currency: "GBP", to_currency: "NZD", exchange_rate: 1.73 },
  { from_currency: "GBP", to_currency: "NOK", exchange_rate: 10.55 },
  { from_currency: "GBP", to_currency: "PLN", exchange_rate: 5.02 },
  { from_currency: "GBP", to_currency: "RON", exchange_rate: 5.82 },
  { from_currency: "GBP", to_currency: "SGD", exchange_rate: 1.83 },
  { from_currency: "GBP", to_currency: "SEK", exchange_rate: 11.76 },
  { from_currency: "GBP", to_currency: "CHF", exchange_rate: 1.27 },
  { from_currency: "GBP", to_currency: "AUD", exchange_rate: 1.78 },

  // # CHF
  { from_currency: "CHF", to_currency: "AUD", exchange_rate: 1.43 },
  { from_currency: "CHF", to_currency: "USD", exchange_rate: 1.05 },
  { from_currency: "CHF", to_currency: "EUR", exchange_rate: 0.93 },
  { from_currency: "CHF", to_currency: "BGN", exchange_rate: 1.81 },
  { from_currency: "CHF", to_currency: "CAD", exchange_rate: 1.28 },
  { from_currency: "CHF", to_currency: "CZK", exchange_rate: 21.00 },
  { from_currency: "CHF", to_currency: "DKK", exchange_rate: 6.79 },
  { from_currency: "CHF", to_currency: "HKD", exchange_rate: 7.42 },
  { from_currency: "CHF", to_currency: "JPY", exchange_rate: 101.50 },
  { from_currency: "CHF", to_currency: "MXN", exchange_rate: 18.50 },
  { from_currency: "CHF", to_currency: "NZD", exchange_rate: 1.38 },
  { from_currency: "CHF", to_currency: "NOK", exchange_rate: 8.43 },
  { from_currency: "CHF", to_currency: "PLN", exchange_rate: 4.02 },
  { from_currency: "CHF", to_currency: "RON", exchange_rate: 4.66 },
  { from_currency: "CHF", to_currency: "SGD", exchange_rate: 1.46 },
  { from_currency: "CHF", to_currency: "SEK", exchange_rate: 9.38 },
  { from_currency: "CHF", to_currency: "GBP", exchange_rate: 0.79 },
  { from_currency: "CHF", to_currency: "AUD", exchange_rate: 1.43 },

  // # SEK
  { from_currency: "SEK", to_currency: "AUD", exchange_rate: 0.15 },
  { from_currency: "SEK", to_currency: "USD", exchange_rate: 0.11 },
  { from_currency: "SEK", to_currency: "EUR", exchange_rate: 0.10 },
  { from_currency: "SEK", to_currency: "BGN", exchange_rate: 0.20 },
  { from_currency: "SEK", to_currency: "CAD", exchange_rate: 0.14 },
  { from_currency: "SEK", to_currency: "CZK", exchange_rate: 16.89 },
  { from_currency: "SEK", to_currency: "DKK", exchange_rate: 5.47 },
  { from_currency: "SEK", to_currency: "HKD", exchange_rate: 5.98 },
  { from_currency: "SEK", to_currency: "JPY", exchange_rate: 81.50 },
  { from_currency: "SEK", to_currency: "MXN", exchange_rate: 14.85 },
  { from_currency: "SEK", to_currency: "NZD", exchange_rate: 0.11 },
  { from_currency: "SEK", to_currency: "NOK", exchange_rate: 0.67 },
  { from_currency: "SEK", to_currency: "PLN", exchange_rate: 0.32 },
  { from_currency: "SEK", to_currency: "RON", exchange_rate: 0.37 },
  { from_currency: "SEK", to_currency: "SGD", exchange_rate: 0.12 },
  { from_currency: "SEK", to_currency: "CHF", exchange_rate: 0.10 },
  { from_currency: "SEK", to_currency: "GBP", exchange_rate: 0.085 },
  { from_currency: "SEK", to_currency: "AUD", exchange_rate: 0.15 },

  // # NOK
  { from_currency: "NOK", to_currency: "AUD", exchange_rate: 0.20 },
  { from_currency: "NOK", to_currency: "USD", exchange_rate: 0.15 },
  { from_currency: "NOK", to_currency: "EUR", exchange_rate: 0.14 },
  { from_currency: "NOK", to_currency: "BGN", exchange_rate: 0.28 },
  { from_currency: "NOK", to_currency: "CAD", exchange_rate: 0.20 },
  { from_currency: "NOK", to_currency: "CZK", exchange_rate: 24.80 },
  { from_currency: "NOK", to_currency: "DKK", exchange_rate: 8.02 },
  { from_currency: "NOK", to_currency: "HKD", exchange_rate: 8.78 },
  { from_currency: "NOK", to_currency: "JPY", exchange_rate: 119.50 },
  { from_currency: "NOK", to_currency: "MXN", exchange_rate: 21.80 },
  { from_currency: "NOK", to_currency: "NZD", exchange_rate: 0.16 },
  { from_currency: "NOK", to_currency: "PLN", exchange_rate: 0.76 },
  { from_currency: "NOK", to_currency: "RON", exchange_rate: 0.88 },
  { from_currency: "NOK", to_currency: "SGD", exchange_rate: 0.28 },
  { from_currency: "NOK", to_currency: "SEK", exchange_rate: 1.49 },
  { from_currency: "NOK", to_currency: "CHF", exchange_rate: 0.12 },
  { from_currency: "NOK", to_currency: "GBP", exchange_rate: 0.099 },
  { from_currency: "NOK", to_currency: "AUD", exchange_rate: 0.20 },

  // # PLN
  { from_currency: "PLN", to_currency: "AUD", exchange_rate: 0.26 },
  { from_currency: "PLN", to_currency: "USD", exchange_rate: 0.19 },
  { from_currency: "PLN", to_currency: "EUR", exchange_rate: 0.18 },
  { from_currency: "PLN", to_currency: "BGN", exchange_rate: 0.35 },
  { from_currency: "PLN", to_currency: "CAD", exchange_rate: 0.25 },
  { from_currency: "PLN", to_currency: "CZK", exchange_rate: 30.90 },
  { from_currency: "PLN", to_currency: "DKK", exchange_rate: 10.02 },
  { from_currency: "PLN", to_currency: "HKD", exchange_rate: 10.98 },
  { from_currency: "PLN", to_currency: "JPY", exchange_rate: 149.50 },
  { from_currency: "PLN", to_currency: "MXN", exchange_rate: 27.30 },
  { from_currency: "PLN", to_currency: "NZD", exchange_rate: 0.20 },
  { from_currency: "PLN", to_currency: "NOK", exchange_rate: 1.32 },
  { from_currency: "PLN", to_currency: "RON", exchange_rate: 1.53 },
  { from_currency: "PLN", to_currency: "SGD", exchange_rate: 0.48 },
  { from_currency: "PLN", to_currency: "SEK", exchange_rate: 3.09 },
  { from_currency: "PLN", to_currency: "CHF", exchange_rate: 0.26 },
  { from_currency: "PLN", to_currency: "GBP", exchange_rate: 0.21 },
  { from_currency: "PLN", to_currency: "AUD", exchange_rate: 0.26 },

  // # RON
  { from_currency: "RON", to_currency: "AUD", exchange_rate: 0.29 },
  { from_currency: "RON", to_currency: "USD", exchange_rate: 0.21 },
  { from_currency: "RON", to_currency: "EUR", exchange_rate: 0.20 },
  { from_currency: "RON", to_currency: "BGN", exchange_rate: 0.39 },
  { from_currency: "RON", to_currency: "CAD", exchange_rate: 0.28 },
  { from_currency: "RON", to_currency: "CZK", exchange_rate: 34.60 },
  { from_currency: "RON", to_currency: "DKK", exchange_rate: 11.19 },
  { from_currency: "RON", to_currency: "HKD", exchange_rate: 12.24 },
  { from_currency: "RON", to_currency: "JPY", exchange_rate: 166.50 },
  { from_currency: "RON", to_currency: "MXN", exchange_rate: 30.50 },
  { from_currency: "RON", to_currency: "NZD", exchange_rate: 0.23 },
  { from_currency: "RON", to_currency: "NOK", exchange_rate: 1.51 },
  { from_currency: "RON", to_currency: "PLN", exchange_rate: 1.65 },
  { from_currency: "RON", to_currency: "SGD", exchange_rate: 0.52 },
  { from_currency: "RON", to_currency: "SEK", exchange_rate: 3.35 },
  { from_currency: "RON", to_currency: "CHF", exchange_rate: 0.28 },
  { from_currency: "RON", to_currency: "GBP", exchange_rate: 0.23 },
  { from_currency: "RON", to_currency: "AUD", exchange_rate: 0.29 },

  // # SGD
  { from_currency: "SGD", to_currency: "AUD", exchange_rate: 1.01 },
  { from_currency: "SGD", to_currency: "USD", exchange_rate: 0.73 },
  { from_currency: "SGD", to_currency: "EUR", exchange_rate: 0.67 },
  { from_currency: "SGD", to_currency: "BGN", exchange_rate: 1.31 },
  { from_currency: "SGD", to_currency: "CAD", exchange_rate: 0.94 },
  { from_currency: "SGD", to_currency: "CZK", exchange_rate: 12.26 },
  { from_currency: "SGD", to_currency: "DKK", exchange_rate: 3.97 },
  { from_currency: "SGD", to_currency: "HKD", exchange_rate: 4.34 },
  { from_currency: "SGD", to_currency: "JPY", exchange_rate: 59.20 },
  { from_currency: "SGD", to_currency: "MXN", exchange_rate: 10.80 },
  { from_currency: "SGD", to_currency: "NZD", exchange_rate: 0.80 },
  { from_currency: "SGD", to_currency: "NOK", exchange_rate: 4.89 },
  { from_currency: "SGD", to_currency: "PLN", exchange_rate: 2.32 },
  { from_currency: "SGD", to_currency: "RON", exchange_rate: 2.68 },
  { from_currency: "SGD", to_currency: "SEK", exchange_rate: 6.84 },
  { from_currency: "SGD", to_currency: "CHF", exchange_rate: 0.73 },
  { from_currency: "SGD", to_currency: "GBP", exchange_rate: 0.60 },
  { from_currency: "SGD", to_currency: "AUD", exchange_rate: 1.01 },

  // # MXN
  { from_currency: "MXN", to_currency: "AUD", exchange_rate: 0.073 },
  { from_currency: "MXN", to_currency: "USD", exchange_rate: 0.051 },
  { from_currency: "MXN", to_currency: "EUR", exchange_rate: 0.047 },
  { from_currency: "MXN", to_currency: "BGN", exchange_rate: 0.093 },
  { from_currency: "MXN", to_currency: "CAD", exchange_rate: 0.067 },
  { from_currency: "MXN", to_currency: "CZK", exchange_rate: 8.68 },
  { from_currency: "MXN", to_currency: "DKK", exchange_rate: 2.81 },
  { from_currency: "MXN", to_currency: "HKD", exchange_rate: 3.08 },
  { from_currency: "MXN", to_currency: "JPY", exchange_rate: 41.90 },
  { from_currency: "MXN", to_currency: "NZD", exchange_rate: 0.31 },
  { from_currency: "MXN", to_currency: "NOK", exchange_rate: 1.83 },
  { from_currency: "MXN", to_currency: "PLN", exchange_rate: 0.85 },
  { from_currency: "MXN", to_currency: "RON", exchange_rate: 0.98 },
  { from_currency: "MXN", to_currency: "SGD", exchange_rate: 0.31 },
  { from_currency: "MXN", to_currency: "SEK", exchange_rate: 2.00 },
  { from_currency: "MXN", to_currency: "CHF", exchange_rate: 0.17 },
  { from_currency: "MXN", to_currency: "GBP", exchange_rate: 0.14 },
  { from_currency: "MXN", to_currency: "AUD", exchange_rate: 0.073 },

  // # NZD
  { from_currency: "NZD", to_currency: "AUD", exchange_rate: 1.07 },
  { from_currency: "NZD", to_currency: "USD", exchange_rate: 0.76 },
  { from_currency: "NZD", to_currency: "EUR", exchange_rate: 0.70 },
  { from_currency: "NZD", to_currency: "BGN", exchange_rate: 1.37 },
  { from_currency: "NZD", to_currency: "CAD", exchange_rate: 0.99 },
  { from_currency: "NZD", to_currency: "CZK", exchange_rate: 12.80 },
  { from_currency: "NZD", to_currency: "DKK", exchange_rate: 4.15 },
  { from_currency: "NZD", to_currency: "HKD", exchange_rate: 4.55 },
  { from_currency: "NZD", to_currency: "JPY", exchange_rate: 62.10 },
  { from_currency: "NZD", to_currency: "MXN", exchange_rate: 3.26 },
  { from_currency: "NZD", to_currency: "NOK", exchange_rate: 6.15 },
  { from_currency: "NZD", to_currency: "PLN", exchange_rate: 2.89 },
  { from_currency: "NZD", to_currency: "RON", exchange_rate: 3.35 },
  { from_currency: "NZD", to_currency: "SGD", exchange_rate: 1.07 },
  { from_currency: "NZD", to_currency: "SEK", exchange_rate: 6.86 },
  { from_currency: "NZD", to_currency: "CHF", exchange_rate: 0.58 },
  { from_currency: "NZD", to_currency: "GBP", exchange_rate: 0.47 },
  { from_currency: "NZD", to_currency: "AUD", exchange_rate: 1.07 },

  { from_currency: "CRC", to_currency: "AUD", exchange_rate: 0.003036 },
  { from_currency: "CRC", to_currency: "EUR", exchange_rate: 0.001843 },
  { from_currency: "CRC", to_currency: "BGN", exchange_rate: 0.003602 },
  { from_currency: "CRC", to_currency: "CAD", exchange_rate: 0.002709 },
  { from_currency: "CRC", to_currency: "CZK", exchange_rate: 0.046365 },
  { from_currency: "CRC", to_currency: "DKK", exchange_rate: 0.013749 },
  { from_currency: "CRC", to_currency: "HKD", exchange_rate: 0.015383 },
  { from_currency: "CRC", to_currency: "JPY", exchange_rate: 0.310124, },
  { from_currency: "CRC", to_currency: "MXN", exchange_rate: 0.033726 },
  { from_currency: "CRC", to_currency: "NZD", exchange_rate: 0.003344, },
  { from_currency: "CRC", to_currency: "NOK", exchange_rate: 0.021862 },
  { from_currency: "CRC", to_currency: "PLN", exchange_rate: 0.007985 },
  { from_currency: "CRC", to_currency: "RON", exchange_rate: 0.009171, },
  { from_currency: "CRC", to_currency: "SGD", exchange_rate: 0.002684, },
  { from_currency: "CRC", to_currency: "SEK", exchange_rate: 0.02167 },
  { from_currency: "CRC", to_currency: "CHF", exchange_rate: 0.001808 },
  { from_currency: "CRC", to_currency: "GBP", exchange_rate: 0.001574 },
  { from_currency: "CRC", to_currency: "USD", exchange_rate: 0.001966 }
];



