export const FLAT_RATE = 'flatRate';
export const PERCENTAGE_OF_RENTAL = 'percentageOfRental';
export const USAGE_MULTIPLE = 'usageMultiple';

export const REQUIRED_UPFRONT = 'requiredUpfront';
export const REQUIRED_UPFRONT_OPTIONAL = 'requiredUpfrontOptional';
export const DELIVERY_FEE = 'deliveryFee';
export const SHIPPING_FEE = 'shippingFee';

export const feeTypeMapper = {
    "deliveryFee": "Delivery Fee",
    "pickupFee": "Pickup Fee",
    "shippingFee": "Shipping Fee",
    "cleaningFee": "Cleaning Fee",
    "setupFee": "Setup Fee",
    "environmentalFee": "Environmental Fee",
    "fuelFee": "Fuel Fee",
    "usageFee": "Usage Fee",
    "accessoryFee": "Accessory Fee",
    "consumablesFee": "Consumables Fee",
};

export const urgencyFieldOptions = [
    { value: 'I need a reply ASAP', label: 'I need a reply ASAP' },
    { value: 'I can wait 24 hours', label: 'I can wait 24 hours' },
    { value: "I'm flexible", label: "I'm flexible" },
];

export const durations = [
    { value: 'flexible', label: 'Flexible' },
    { value: 'specific', label: 'Specific' }
];

export const FLEXIBLE_DURATION = 'flexible';
export const SPECIFIC_DURATION = 'specific';
export const durationTypes = [
    { value: 'Hours', label: 'Hours' },
    { value: 'Days', label: 'Days' },
]

export const FLEXIBLE_DATE = 'flexible';
export const SPECIFIC_DATE = 'specific'
export const dateOptions = [
    { value: "flexible", label: "Flexible" },
    { value: 'specific', label: 'Specific' }
]

export const deliverPickupOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No, you will pickup', value: 'no, you will pickup' },
    { label: 'No applicable', value: 'no applicable' }
]
export const DELIVERY_YES = 'yes';
export const DELIVERY_NO = 'no, you will pickup';
export const DELIVERY_NOT_APPLICABLE = 'no applicable';

export const radiusOptions = [
    { label: "5 km", value: '5 km' },
    { label: "10 km", value: '10 km' },
    { label: "15 km", value: '15 km' },
    { label: "20 km", value: '20 km' },
    { label: "25 km", value: '25 km' }
]

export const budgetOptions = [
    { label: "Firm", value: 'firm' },
    { label: "Flexible", value: 'flexible' },
    { label: "Range", value: "range" }
];

export const BUDGET_FIRM_OPTION = 'firm';
export const BUDGET_FLEXIBLE_OPTION  = 'flexible';
export const BUDGET_RANGE_OPTION = "range";

export const wayToConnectOptions = [
    {label:"Phone", value:'phone'},
    {label:"Email", value:'email'}
];

export const timeToConnectOptions = [
    {label:'Select best time to connect', value:""},
    { label: "8 AM - 12 PM", value: "8 AM - 12 PM" },
    { label: "12 PM - 4 PM", value: "12 PM - 4 PM" },
    { label: "4 PM - 8 PM", value: "4 PM - 8 PM" }
];

export const PICKUP_AT_OWNER_LOCATION = 'Pickupat_owners_location';
export const facetLabelMappings = {
    category: {
      "Party-Event": "Party & Event",
      "Equipment-Tools": "Equipment & Tools",
      "Sports-Recreation": "Sports & Recreation",
      "Toys-Games-Music-Books-Art": "Toys, Games, Music, Books & Art",
      "Electronics-Furniture": "Electronics & Furniture",
      "photography-video": "Photography & Video",
      "Medical-Accessories": "Medical Accessories",
      "AnimalsAccessories": "Animal Accessories",
      "Kids-Baby": "Kids & Baby",
      "Miscellaneous": "Miscellaneous",
      "Moving": "Moving",
      "RV-Trailers": "RV & Trailers",
      "Fashion-Accessories": "Fashion Accessories",
      "Recreational-Vehicles": "Recreational Vehicles",
      "watercraft-accessories": "Watercraft Accessories",
      "Spaces-Storage": "Spaces & Storage",
      "People-Services": "People & Services",
    },
    security_deposit: {
      "50": "50",
      "100": "100",
      "250": "250",
      "500": "500",
      "no_deposit": "No Deposit",
    },
    access_to_rentals: {
      "Pickupat_owners_location": "Pickup at Owner's Location",
      "delivery_available": "Delivery Available",
      "public_meetup_location": "Public Meetup Location",
      "shipping": "Shipping",
      "service_where_required": "Service Where Required",
      "digitally": "Digitally",
    },
    cancelation_policy: {
      "flexible": "Flexible",
      "easy-going": "Easy Going",
      "strict": "Strict",
    },
    reason_for_listing: {
      "help_the_environment": "Help the Environment",
      "try_before-you_buy": "Try Before You Buy",
      "side_hustle": "Side Hustle",
      "in_the_rental_business": "In the Rental Business",
      "just_because": "Just Because",
    },
    Condition_of_the_item: {
      "Used_Like_New": "Used (Like New)",
      "Used_Good": "Used (Good)",
      "New": "New",
      "Used_Fair": "Used (Fair)",
      "its_a_service": "It's a Service",
    },
    Trust_and_verification: {
      "copy_of_drivers_licience": "Copy of Driver's License",
      "not_required": "Not Required",
      "2_social_media_site_links": "2 Social Media Site Links",
      "minimum_of_3_successful_rent": "Minimum of 3 Successful Rents",
    },
  };

export const ACCESS_OPTIONS = {
  PICKUP: "Pickupat_owners_location",
  DELIVERY: "delivery_available",
  SHIP: "shipping",
  PUBLIC_MEETUP: 'public_meetup_location',
  SERVICE_REQUIRED: 'service_where_required',
  DIGITALLY: 'digitally',
}

export const DEFAULT_CURRENCY = 'USD';